import React, { useEffect, useState, PureComponent } from 'react';
import { Modal, message, Spin, Button, Tag, Card, Space, Row, Col } from 'antd';
import api, { GetProjectRes, GetDynamicFieldTemplateListParameters, GetDynamicFieldTemplateRes, GetSystemRes, GetDynamicFieldRes, GetDynamicModelListParameters, GetDynamicModelListRes, GetDynamicModelDataItemRes, } from '../../api';
import {
    LineChart,
    Legend,
    Line,
    AreaChart,
    XAxis, CartesianGrid, Tooltip, Area, YAxis, ResponsiveContainer
} from 'recharts'
import moment from 'moment';
import { randomColors } from '../../tool/color';

export type StatisicsItemProps = {
    dynamicFieldTemplate: GetDynamicFieldTemplateRes
    dynamicField: GetDynamicFieldRes
    systemAndProjectDatas: (GetSystemRes | GetProjectRes)[]
    beginAt: string
    endAt: string
    isAscend?: boolean
}



const getDynamicModelList = async (params: GetDynamicModelListParameters) => {
    const res = await api.GetDynamicModelList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
}


/**
 * 返回毫秒
 */
const getStatisicsItemInterval = (beginAt: string, endAt: string, ) => {

    return Math.floor(moment(endAt).diff(moment(beginAt)) / (5000 * 3600) + 1) * 5 * 1000

}








export const StatisicsItem = (props: StatisicsItemProps) => {

    const [isSpinLoading, setIsSpinLoading] = useState(true)
    const [data, setData] = useState<any>([])

    const init = async () => {
        try {
            setIsSpinLoading(true)
            const interval = getStatisicsItemInterval(props.beginAt, props.endAt)
            let dmDataListRes = await Promise.all(
                props.systemAndProjectDatas.map(async data => {
                    const dmList = await getDynamicModelList({
                        count: 100000,
                        isAscend: props.isAscend,
                        code: data.code,
                        dynamicFieldId: props.dynamicField.id,
                        beginAt: props.beginAt,
                        endAt: props.endAt,
                        interval,
                    })

                    return dmList.data.map(item => ({ ...item, data }))

                })
            )

            let dmDataObjList: { [x: string]: GetDynamicModelDataItemRes & { data: GetSystemRes | GetProjectRes } }[] = []


            const momentFormat = 'YYYY-MM-DD HH:mm:ss'

            dmDataObjList = dmDataListRes.reduce((pv, cv) => {

                const obj: any = {}
                for (const item of cv) {
                    const key = moment(item.createdAt).format(momentFormat)
                    obj[key] = item
                }
                pv.push(obj)

                return pv
            }, dmDataObjList)



            const filterAry = []
            for (let index = moment(props.beginAt); index < moment(props.endAt); index.add(interval, 'ms')) {
                filterAry.push(moment(index).format(momentFormat))

            }


            const dataTemplate = filterAry.map(value => {

                const valueObj: any = {}

                for (const obj of dmDataObjList) {
                    const data = obj[value]
                    if (data) {
                        valueObj[data.data.code] = data.value
                    }
                }


                return {
                    ...valueObj,
                    name: value,
                }
            })

            setData(dataTemplate)
            setIsSpinLoading(false)
        } catch (error) {
            setIsSpinLoading(false)
        }


    }


    useEffect(() => {
        (() => {
            init()
        })()
        return () => {
            console.log('卸載了')
        }
    }, [])



    return (
        <Col
            className="gutter-row"
            span={12}
        >
            <Card
                style={{
                }}
                hoverable={true}
                type="inner"
                title={props.dynamicField.label}
            >
                <Spin
                    spinning={isSpinLoading}
                >
                    <div
                        style={{
                            width: '100%',
                            height: 350,
                        }}
                    >
                        <ResponsiveContainer width="100%" height="100%">
                            <AreaChart
                                data={data}
                                margin={{
                                    top: 0,
                                    right: 0,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    interval={'preserveStartEnd'}
                                    fontSize={8}
                                    dataKey="name"
                                    tick={<CustomXAxisTick />}
                                    angle={-15}
                                />
                                <YAxis
                                    domain={props.dynamicField.typeUnit === '%' ? [0, 100] : undefined} // 设置Y轴取值范围为0到100
                                    fontSize={10}
                                    tickFormatter={(v, i) => `${v}${props.dynamicField.typeUnit || ''}`} />
                                <Tooltip />
                                <Legend />
                                {props.systemAndProjectDatas.map((item, index) => {
                                    return (
                                        <Area
                                            connectNulls
                                            key={item.code}
                                            type="monotone"
                                            name={item.name}
                                            dataKey={item.code}
                                            stroke={randomColors[index]}
                                            fill={randomColors[index]}
                                        />
                                    )
                                })}
                            </AreaChart>
                        </ResponsiveContainer>


                    </div>
                </Spin>

            </Card>
        </Col>
    )
}




const CustomXAxisTick = (props: any) => {
    const { x, y, payload } = props;
    const words = String(payload.value).split(' '); // 按空格拆分文本
    console.log('CustomXAxisTick', props)
    return (
        <g transform={`translate(${x},${y})`}>
            {words.map((word, index) => (
                <text fontSize={props.fontSize} key={index} x={0} y={-5 + index * 10} dy={16} textAnchor="middle" fill={props.fill}>
                    {word}
                </text>
            ))}
        </g>
    );
};