import React, { useState, useEffect } from 'react';
import { Modal, message, Spin, Button, Tag, Col, Radio, Row, DatePicker, } from 'antd';
import Select, { SelectProps } from 'antd/lib/select';
import Switch, { SwitchSize } from 'antd/lib/switch';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { DatePickerProps } from 'antd/lib/date-picker';





type SourceItemSelect = {
  type: 'select'
  defaultValue?: string | number
  allowClear?: boolean
  key: string
  placeholder?: string
  options: {
    value: string | number
    name: string | JSX.Element
    disabled?: boolean
  }[]
} & SelectProps<any>
interface SwitchItemSelect {
  type: 'switch'
  defaultValue?: boolean
  disabled?: boolean
  unCheckedChildren?: React.ReactNode
  checkedChildren?: React.ReactNode
  size?: SwitchSize
  key: string
}


interface RangePickerItemSelect {
  type: 'rangePicker'
  key: string
  props: RangePickerProps<moment.Moment>
  defaultValue?: any
}


interface DatePickerItemSelect {
  type: 'datePicker'
  key: string
  props: DatePickerProps
  defaultValue?: moment.Moment
}



/**
 * 单选组合
 */
interface RadioGroupItemSelect {
  type: 'radioGroup'
  defaultValue?: string
  key: string
  options: {
    value?: string
    name: string
    disabled?: boolean
  }[]
}

export type SourceItem = (SourceItemSelect | RadioGroupItemSelect | SwitchItemSelect | RangePickerItemSelect | DatePickerItemSelect) & { span?: number, onChange?(key: string, value: any): void }


const SourceItem = (item: SourceItem) => {
  const onChange = async (key: string, value: any) => {
    item.onChange && item.onChange(key, value)
  }

  switch (item.type) {
    case 'switch': {
      return (
        <Switch
          style={{ width: '100%' }}
          defaultChecked={item.defaultValue}
          disabled={item.disabled}
          size={item.size}
          checkedChildren={item.checkedChildren}
          unCheckedChildren={item.unCheckedChildren}
          onChange={e => {
            onChange(item.key, e)
          }}
        />
      )
    }
    case 'select': {
      return (
        <Select
          {...item}
          style={{ width: '100%' }}
          allowClear={item.allowClear}
          defaultValue={item.defaultValue}
          placeholder={item.placeholder}
          dropdownMatchSelectWidth={false}
          filterOption={item.filterOption}
          showSearch={item.showSearch}
          mode={item.mode}
          onChange={e => {
            onChange(item.key, e)
          }}
          options={undefined}
        >
          {item.options.map((item, index) => {
            return (
              <Select.Option
                key={index}
                disabled={item.disabled}
                value={item.value}>{item.name}</Select.Option>
            )
          })}
        </Select>
      )
    }
    case 'radioGroup': {
      return (
        <Radio.Group
          style={{ width: '100%' }}
          defaultValue={item.defaultValue}
          onChange={e => {
            onChange(item.key, e.target.value)
          }}
        >

          {item.options.map((item, index) => {
            return (
              <Radio.Button
                key={index}
                disabled={item.disabled}
                value={item.value}
              >{item.name}</Radio.Button>
            )
          })}
        </Radio.Group>
      )
    }
    case 'rangePicker': {
      return (
        <DatePicker.RangePicker
          style={{ width: '100%' }}
          {...item.props}
          defaultValue={item.defaultValue}
          onChange={e => {
            onChange(item.key, e)
          }}
        />
      )
    }
    case 'datePicker': {
      return (
        <DatePicker
          style={{ width: '100%' }}
          {...item.props}
          defaultValue={item.defaultValue}
          onChange={e => {
            onChange(item.key, e)
          }}
        />
      )
    }

  }
}



export type StatisicsHeaderViewProps<T> = {
  sourceItems: SourceItem[]
  onChange?(data: T): void
  style?: React.CSSProperties
}



let changeData: any = {}
let changeTimeout: any

export const StatisicsHeaderView = <T extends { [x: string]: any }>(props: StatisicsHeaderViewProps<T>) => {
  const onChagne = (key: string, value: any) => {

    changeData = {
      ...changeData,
      [key]: value
    }
    changeTimeout && clearTimeout(changeTimeout)

    changeTimeout = setTimeout(() => {
      props.onChange && props.onChange(changeData)
    }, 1000);

  }

  const init = () => {
    console.log('init')
    for (const item of props.sourceItems) {
      changeData = {
        ...changeData,
        [item.key]: item.defaultValue
      }
    }

    props.onChange && props.onChange(changeData)


  }

  useEffect(() => {
    (() => {
      init()
    })()
    return () => {
      console.log('卸載了')
    }
  }, [])

  return (
    <Row
      style={props.style}
      gutter={[12, 12]} >
      {props.sourceItems.map(item => (
        <Col
          key={item.key}
          className="gutter-row" span={item.span || 4}
        >

          {SourceItem({
            ...item,
            onChange: (k, v) => {
              item.onChange && item.onChange(k, v)
              onChagne(k, v)
            }
          })}
        </Col>
      ))}
    </Row>
  )

}

