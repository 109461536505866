import api, { GetDynamicFieldWarnSetParameters, PutDynamicFieldWarnSetParameters, GetDynamicFieldParameters } from "."
import { GetDynamicFieldListParameters, GetSystemListParameters, GetProjectListParameters, GetFieldWarnListParameters, GetFieldWarnParameters, GetDynamicFieldTemplateListParameters, GetDynamicModelListParameters } from "./api-parameters-type"

export const getDynamicFieldWarnSet = async (params: GetDynamicFieldWarnSetParameters) => {
  const res = await api.GetDynamicFieldWarnSet(params)
  if (res.kind !== 'ok') throw new Error(res.kind)
  return res.data
}

export const putDynamicFieldWarnSet = async (params: PutDynamicFieldWarnSetParameters) => {
  const res = await api.PutDynamicFieldWarnSet(params)
  if (res.kind !== 'ok') throw new Error(res.kind)
  return res.data
}

export const getDynamicField = async (params: GetDynamicFieldParameters) => {
  const res = await api.GetDynamicField(params)
  if (res.kind !== 'ok') throw new Error(res.kind)
  return res.data
}


export const getDynamicFieldList = async (params: GetDynamicFieldListParameters) => {
  const res = await api.GetDynamicFieldList(params)
  if (res.kind !== 'ok') throw new Error(res.kind)
  return res.data
}

export const getSystemList = async (params: GetSystemListParameters) => {
  const res = await api.GetSystemList(params)
  if (res.kind !== 'ok') throw new Error(res.kind)
  return res.data
}
export const getProjectList = async (params: GetProjectListParameters) => {
  const res = await api.GetProjectList(params)
  if (res.kind !== 'ok') throw new Error(res.kind)
  return res.data
}
export const getFieldWarnList = async (params: GetFieldWarnListParameters) => {
  const res = await api.GetFieldWarnList(params)
  if (res.kind !== 'ok') throw new Error(res.kind)
  return res.data
}
export const getFieldWarn = async (params: GetFieldWarnParameters) => {
  const res = await api.GetFieldWarn(params)
  if (res.kind !== 'ok') throw new Error(res.kind)
  return res.data
}

export const getDynamicFieldTemplateList = async (params: GetDynamicFieldTemplateListParameters) => {
  const res = await api.GetDynamicFieldTemplateList(params)
  if (res.kind !== 'ok') throw new Error(res.kind)
  return res.data
}

export const getDynamicModelList = async (params: GetDynamicModelListParameters) => {
  const res = await api.GetDynamicModelList(params)
  if (res.kind !== 'ok') throw new Error(res.kind)
  return res.data
}
