import React, { useEffect, useState } from 'react';
import { Modal, message, Spin, Button, Tag, Card, Space, Row, Col } from 'antd';
import api, { GetProjectRes, GetDynamicFieldTemplateListParameters, GetDynamicFieldTemplateRes, GetSystemRes, GetDynamicFieldRes, GetDynamicModelListParameters, GetDynamicModelListRes, } from '../../api';
import { StatisicsItem } from './StatisicsItem';


export type StatisicsProps = {
    dynamicFieldTemplate: GetDynamicFieldTemplateRes
    systemAndProjectDatas: (GetSystemRes | GetProjectRes)[],
    dynamicFieldDatas: GetDynamicFieldRes[],
    beginAt: string
    endAt: string
    isAscend?: boolean
}


export const Statisics = (props: StatisicsProps) => {
    console.log('Statisics', props)

    return (
        <Card
            title={props.dynamicFieldTemplate.name}
        >
            <Row
                gutter={[12, 12]}
                style={{
                }}
            >

                {props.dynamicFieldDatas.map(dfData => {
                    return (
                        <StatisicsItem
                            key={dfData.id}
                            dynamicFieldTemplate={props.dynamicFieldTemplate}
                            dynamicField={dfData}
                            systemAndProjectDatas={props.systemAndProjectDatas}
                            beginAt={props.beginAt}
                            endAt={props.endAt}
                            isAscend={props.isAscend}
                        />

                    )
                })}


            </Row>





        </Card>
    )
}



