import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag, Space } from 'antd';
import api, { GetProjectRes, GetDynamicFieldTemplateListParameters, GetDynamicFieldTemplateListRes, GetSystemListParameters, GetProjectParameters, GetSystemRes, GetProjectListParameters, GetDynamicFieldTemplateRes, GetDynamicFieldRes, GetDynamicFieldListParameters, } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import { connect, ConnectedProps } from 'react-redux';
import { ActionUserRes } from '../../actions/ActionUser';
import { StatisicsHeaderView, SourceItem } from './StatisicsHeaderView';
import tool from '../../tool';
import moment from 'moment';
import Moment from 'react-moment';
import { Statisics, StatisicsProps } from './Statisics';

const { confirm } = Modal;


interface PageState {
  isSpinLoading: boolean
  dynamicFieldTemplateList: GetDynamicFieldTemplateListRes['data']
  codes: string[],
  systemAndProjectDatas: (GetSystemRes | GetProjectRes)[],
  dynamicFields: GetDynamicFieldRes[],
  dynamicFieldKeys: string[],
  statisicsProps?: StatisicsProps & { key: any }

}
interface Props {
}


interface Page {

}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class DynamicModelStatistics extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);

    this.state = {
      isSpinLoading: true,
      dynamicFieldTemplateList: [],
      codes: [],
      systemAndProjectDatas: [],
      dynamicFields: [],
      dynamicFieldKeys: [],
    }
  }
  tableRef?: JJ_Table<GetProjectRes> | null
  componentDidMount() {
    this._initData()
  }




  _getDynamicFieldTemplateList = async (params: GetDynamicFieldTemplateListParameters) => {
    const res = await api.GetDynamicFieldTemplateList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _getDynamicFieldList = async (params: GetDynamicFieldListParameters) => {
    const res = await api.GetDynamicFieldList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }



  _getSystemList = async (params: GetSystemListParameters) => {
    const res = await api.GetSystemList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getProjectList = async (params: GetProjectListParameters) => {
    const res = await api.GetProjectList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _initDynamicFieldTemplate = async (dynamicFieldTemplateList: GetDynamicFieldTemplateRes[], dynamicFieldTemplateId: string) => {

    try {

      const dft = dynamicFieldTemplateList.find(item => item.id === dynamicFieldTemplateId)
      if (dft) {

        const dfListRes = await this._getDynamicFieldList({ count: 10000, dynamicFieldTemplateId: dft?.id })


        let systemAndProjectDatas: (GetSystemRes | GetProjectRes)[] = []
        switch (dft?.type) {
          case 'Project': {
            const projectListRes = await this._getProjectList({ count: 10000, dynamicFieldTemplateId: dft.id })
            systemAndProjectDatas = projectListRes.data
          }
            break
          case 'System': {
            const systemListRes = await this._getSystemList({ count: 10000, dynamicFieldTemplateId: dft.id })
            systemAndProjectDatas = systemListRes.data
          }
            break

        }

        this.setState({
          systemAndProjectDatas: systemAndProjectDatas,
          dynamicFields: dfListRes.data,
        })


      }




    } catch (error) {

    }


  }

  _initData = async () => {

    this.setState({
      isSpinLoading: true
    })

    try {

      const paramsData = this._getSearchParams(this.props.location.search)
      const dynamicFieldTemplateId = paramsData['dynamicFieldTemplateId']
      const codes = paramsData['codes'] ? JSON.parse(paramsData['codes']) : []
      const dynamicFieldKeys = paramsData['dynamicFieldKeys'] ? JSON.parse(paramsData['dynamicFieldKeys']) : []
      const dftList = await this._getDynamicFieldTemplateList({ count: 1000 })

      const dft = dftList.data.find(item => item.id === dynamicFieldTemplateId)
      if (dft) {

        const dfListRes = await this._getDynamicFieldList({ count: 10000, dynamicFieldTemplateId: dft?.id })


        let systemAndProjectDatas: (GetSystemRes | GetProjectRes)[] = []
        switch (dft?.type) {
          case 'Project': {
            const projectListRes = await this._getProjectList({ count: 10000, dynamicFieldTemplateId: dft.id })
            systemAndProjectDatas = projectListRes.data
          }
            break
          case 'System': {
            const systemListRes = await this._getSystemList({ count: 10000, dynamicFieldTemplateId: dft.id })
            systemAndProjectDatas = systemListRes.data
          }
            break

        }


        this.setState({
          isSpinLoading: false,
          dynamicFieldTemplateList: dftList.data,
          codes,
          dynamicFieldKeys,
          systemAndProjectDatas: systemAndProjectDatas,
          dynamicFields: dfListRes.data,
        })

      }





    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }

  _getSearchParams = (search: string) => {

    const params = new URLSearchParams(search);


    const sourceItems = this._getSourceItems()

    let paramsData: { [x: string]: any } = {}
    for (const item of sourceItems) {

      paramsData = {
        ...paramsData,
        [item.key]: params.get(item.key) || undefined
      }
    }



    return paramsData
  }

  _getSourceItems = (paramsData?: any): SourceItem[] => {

    paramsData = paramsData || {}
    const rangePicker = paramsData['rangePicker'] ? JSON.parse(paramsData['rangePicker']) : undefined
    return [
      {
        span: 5,
        type: 'select',
        defaultValue: paramsData['dynamicFieldTemplateId'],
        placeholder: '任意模板',
        allowClear: false,
        key: 'dynamicFieldTemplateId',
        options: this.state.dynamicFieldTemplateList.map(item => ({
          value: item.id,
          name: item.name,
          disabled: false,
        })),
        onChange: (key: string, value: any) => {
          this.setState({
            codes: [],
            dynamicFieldKeys: [],
          })
          this._initDynamicFieldTemplate(this.state.dynamicFieldTemplateList, value)
        }
      },

      {
        span: 7,
        type: 'select',
        defaultValue: paramsData['codes'] ? JSON.parse(paramsData['codes']) : [],
        value: this.state.codes,
        placeholder: '任意系統或項目',
        allowClear: true,
        mode: 'multiple',
        key: 'codes',
        options: this.state.systemAndProjectDatas.map(item => ({
          value: item.code,
          name: item.name,
          disabled: false,
        })),
        onChange: (key: string, value: any) => {
          this.setState({
            codes: value
          })
        }
      },
      {
        type: 'rangePicker',
        key: 'rangePicker',
        defaultValue: rangePicker ? [rangePicker[0] ? moment(rangePicker[0]) : undefined, rangePicker[1] ? moment(rangePicker[1]) : undefined] : [moment().subtract('hours', 1), moment()],
        span: 12,
        props: {
          placeholder: ['開始時間', '結束時間'],
          ranges: {
            '1小時前': [moment().subtract('hours', 1), moment()],
            '3小時前': [moment().subtract('hours', 3), moment()],
            '6小時前': [moment().subtract('hours', 6), moment()],
            '1天前': [moment().subtract('days', 1), moment()],
            '7天前': [moment().subtract('days', 7), moment()],
            '一個月前': [moment().subtract('months', 1), moment()],
          },
          showTime: true,
          format: "YYYY/MM/DD HH:mm",
          allowClear: false,
        }
      },
      {
        span: 24,
        type: 'select',
        defaultValue: paramsData['dynamicFieldKeys'] ? JSON.parse(paramsData['dynamicFieldKeys']) : [],
        value: this.state.dynamicFieldKeys,
        placeholder: '任意字段',
        allowClear: true,
        mode: 'multiple',
        key: 'dynamicFieldKeys',
        options: this.state.dynamicFields.map(item => ({
          value: item.key,
          name: (
            <Space>
              <Tag color='blue'>{item.label}</Tag>
            </Space>
          ),
          disabled: false,
        })),
        onChange: (key: string, value: any) => {
          this.setState({
            dynamicFieldKeys: value
          })
        }
      },



    ]


  }
  _view = () => {
    const paramsData = this._getSearchParams(this.props.location.search)

    return (
      <div>
        <StatisicsHeaderView<{
          dynamicFieldTemplateId: string | undefined
          codes?: string[]
          dynamicFieldKeys?: string[]
          rangePicker?: moment.Moment[]
        }>
          sourceItems={this._getSourceItems(paramsData)}
          onChange={params => {



            params = { ...params, codes: this.state.codes, dynamicFieldKeys: this.state.dynamicFieldKeys }





            tool.route.pushParamsObjHistory(params, this.props.history)
            const [beginAtMoment, endAtMoment] = params.rangePicker || []
            const dynamicFieldTemplate = this.state.dynamicFieldTemplateList.find(item => item.id === params.dynamicFieldTemplateId)

            if (dynamicFieldTemplate) {
              this.setState({
                statisicsProps: {
                  key: Date.now(),
                  endAt: moment(endAtMoment).startOf('m').toISOString(),
                  beginAt: moment(beginAtMoment).startOf('m').toISOString(),
                  dynamicFieldTemplate,
                  systemAndProjectDatas: this.state.systemAndProjectDatas.filter(item => params.codes?.some(item2 => item2 === item.code)),
                  dynamicFieldDatas: this.state.dynamicFields.filter(item => params.dynamicFieldKeys?.some(item2 => item2 === item.key)),
                }
              })
            }



          }}
        />
      </div>
    )
  }

  render() {
    return (
      <div id="DynamicModelStatistics">
        <h2 style={{ padding: '10px,0px' }}>統計</h2>
        <Spin
          spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && this._view()}
        </Spin>
        {this.state.statisicsProps && (
          <Statisics
            {...this.state.statisicsProps}
          />
        )}

      </div>
    )
  }

}
export default connector(DynamicModelStatistics)

