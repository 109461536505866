import React, { useState, useEffect } from 'react';
import { Col, Card, Space, Tag, Spin } from 'antd';
import {
  Tooltip,
  ResponsiveContainer,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar
} from 'recharts';
import { StatisicsHeaderView } from '../dynamicModelStatistics/StatisicsHeaderView';
import { getDynamicFieldList, getDynamicFieldTemplateList, getSystemList, getDynamicModelList } from '../../api/myApi';
import { GetDynamicFieldRes, GetSystemRes } from '../../api';


interface IDataPoint {
  machines: string[];
  name?: string
  r: number
}




/**
 * 系統範圍分佈圖
 */
export const SysDistMap = () => {

  const [selectDynamicFieldKey, setSelectDynamicFieldKey] = useState<string>()
  const [dynamicFields, setDynamicFields] = useState<GetDynamicFieldRes[]>([])
  const [systems, setSystems] = useState<GetSystemRes[]>([])
  const [isSpinLoading, setIsSpinLoading] = useState<boolean>(true)
  const [machineDistributionChartKey, setMachineDistributionChartKey] = useState<any>()


  const init = async () => {

    try {

      setIsSpinLoading(true)
      const dftList = await getDynamicFieldTemplateList({ count: 1000, type: 'System' })
      const dynamicFieldTemplateId = dftList.data[0]?.id
      if (dynamicFieldTemplateId) {
        const dfListRes = await getDynamicFieldList({ count: 10000, dynamicFieldTemplateId })
        const systemListRes = await getSystemList({ count: 10000, dynamicFieldTemplateId })



        setDynamicFields(
          dfListRes.data
            .filter(item => item?.typeUnit === '%')
        )
        setSelectDynamicFieldKey(dfListRes.data[0]?.id)
        setSystems(systemListRes.data)
      }
      setIsSpinLoading(false)

    } catch (error) {
      setIsSpinLoading(false)

    }
  }



  useEffect(() => {
    (() => {
      init()
    })()
    return () => {
      console.log('WarnSetEditModal_卸載了')
    }
  }, [])


  const dynamicField = dynamicFields.find(item => item.id === selectDynamicFieldKey)

  return (
    <Col span={24}>
      <Card
        title={'系統範圍分佈圖'}
      >

        <Spin
          spinning={isSpinLoading}>
          {!isSpinLoading && (

            <>
              <StatisicsHeaderView<{
                dynamicFieldKey?: string
              }>
                sourceItems={[
                  {
                    span: 24,
                    type: 'select',
                    defaultValue: selectDynamicFieldKey,
                    value: selectDynamicFieldKey,
                    placeholder: '任意字段',
                    allowClear: true,
                    key: 'dynamicFieldKey',
                    options: dynamicFields.map(item => ({
                      value: item.id,
                      name: (
                        <Space>
                          <Tag color='blue'>{item.label}</Tag>
                        </Space>
                      ),
                      disabled: false,
                    })),
                  },
                ]}
                onChange={params => {
                  setSelectDynamicFieldKey(params.dynamicFieldKey)
                  setMachineDistributionChartKey(Date.now())

                }}
              />

              {dynamicField && machineDistributionChartKey && (
                <MachineDistributionChart
                  key={machineDistributionChartKey}
                  systems={systems}
                  dynamicField={dynamicField}
                />
              )}

            </>
          )
          }
        </Spin>


      </Card>
    </Col>


  )
}





const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    // 分割数组为每组5个
    const name = payload[0]?.payload?.name
    const chunkSize = 4;
    const machineGroups = payload[0].payload.machines.reduce((resultArray: any[][], item: any, index: number) => {
      const chunkIndex = Math.floor(index / chunkSize);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);

    // 将每一组数组转换成字符串，并使用换行符拼接
    const machines = machineGroups.map((group: any[]) => group.join(", ")).join("\n");

    return (
      <div style={{
        background: 'linear-gradient(145deg, rgba(255,255,255,0.1), rgba(0,0,0,0.1))',
        padding: '10px',
        border: '1px solid #fff',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
        color: '#fff',
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif'
      }}>
        <p style={{
          color: 'rgba(0, 0, 0, 0.5)',
          margin: 0,
          fontWeight: 'bold',
          fontSize: '12px',
          textAlign: 'center',
          whiteSpace: 'pre-wrap' // 使用 pre-wrap 保持换行符的效果
        }}>{`${name} \n${machines}`}</p>
      </div>
    );
  }
  return null;
};



type MachineDistributionChart = {
  systems: GetSystemRes[]
  dynamicField: GetDynamicFieldRes
}

const MachineDistributionChart = (props: MachineDistributionChart) => {


  const [isSpinLoading, setIsSpinLoading] = useState(true)
  const [data, setData] = useState<IDataPoint[]>([])


  const init = async () => {
    try {
      setIsSpinLoading(true)

      let newSystems = await Promise.all(
        props.systems.map(async (data, index) => {

          const dmList = await getDynamicModelList({
            count: 100,
            code: data.code,
            dynamicFieldId: props.dynamicField.id,
          })


          let dmValue = dmList.data.reduce((pv, cv) => {
            return Number(cv.value || 0) + pv
          }, 0)

          return { ...data, dmValue: dmValue / dmList.data.length }
        })
      )



      const indexTemplateA = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
      const indexTemplateB = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90,]

      const ranges = indexTemplateA.map((upperBound, i) => ({
        lowerBound: indexTemplateB[i] || 0,
        upperBound
      }));


      const iDataPoints: IDataPoint[] = ranges.map(({ lowerBound, upperBound }) => {
        const machines = newSystems
          .filter(({ dmValue }) => {
            const value = Number(dmValue || 0);
            return value > lowerBound && value <= upperBound;
          })
          .map(system => system.name);

        return { machines, r: machines.length, name: `${upperBound}%` };
      });

      setData(iDataPoints)
      setIsSpinLoading(false)
    } catch (error) {
      setIsSpinLoading(false)
    }


  }


  useEffect(() => {
    (() => {
      init()
    })()
    return () => {
      console.log('MachineDistributionChart 卸載了')
    }
  }, [])



  return (

    <Spin
      spinning={isSpinLoading}
    >

      <Card
        style={{
        }}
        hoverable={true}
        type="inner"
        title={props.dynamicField.label}
      >

        <ResponsiveContainer width="100%" height={800}>
          <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
            <PolarGrid />
            <PolarAngleAxis dataKey="name" />
            <PolarRadiusAxis />
            <Radar name="Machines" dataKey="r" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
            <Tooltip content={<CustomTooltip />} />
          </RadarChart>
        </ResponsiveContainer>

      </Card>



    </Spin>

  );
};



