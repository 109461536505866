import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api from '../../api';
import { Select, Tag } from 'antd';
import { connect, ConnectedProps } from 'react-redux';



interface PageState {

}

type Type = { type: 'add' } | { type: 'edit', id: string }

interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & Type & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class DynamicFieldTemplateEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);

    this.state = {

    }

  }
  componentDidMount() {
  }

  _onFinish = async (values: any): Promise<void> => {

    switch (this.props.type) {
      case 'edit': {
        const res = await api.PutDynamicFieldTemplate({
          ...values,
          id: this.props.id,
        })
        if (res.kind !== 'ok') throw Error(res.kind)
        this.props.onFinish && this.props.onFinish(this.props, this.props.id)
      }
        break
      case 'add': {
        const res = await api.PostDynamicFieldTemplate({
          ...values,
        })
        if (res.kind !== 'ok') throw Error(res.kind)
        this.props.onFinish && this.props.onFinish(this.props, 'xxxx')

      }
        break
    }
  }
  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {
      case 'edit': {
        const res = await api.GetDynamicFieldTemplate({ id: this.props.id })

        if (res.kind === 'ok') {
          return res.data
        }
        throw Error(res.kind)
      }
      case 'add': {
        return
      }

    }
  };

  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [
          {
            id: 'name',
            label: '名稱',
            rules: [
              {
                required: true,
              }
            ],
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {
        return [

          {
            id: 'type',
            label: '類型',
            rules: [
              {
                required: true,
              }
            ],
            componet: (
              <Select>
                {[
                  {
                    value: 'System',
                    name: '系統',
                  },
                  {
                    value: 'Project',
                    name: '項目',
                  },
                ].map((quota) => {
                  return (
                    <Select.Option key={quota.value} value={quota.value}>{quota.name}</Select.Option>
                  )
                })}
              </Select>
            )
          },
          {
            id: 'name',
            label: '名稱',
            rules: [
              {
                required: true,
              }
            ],
          },
          {
            id: 'submit',
          },
        ]
      }

    }
  }

  render() {
    return (
      <div id="DynamicFieldTemplateEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </div>
    )
  }

}
export default connector(DynamicFieldTemplateEditAndAdd)
