import React, { useState } from 'react';
import { Card, Select, Input, Slider, message } from 'antd';
import api, { GetDynamicFieldRes, GetDynamicFieldWarnSetParameters, PutDynamicFieldWarnSetParameters, GetDynamicFieldWarnSetRes, GetDynamicFieldParameters, } from '../../api';
import JJ_From, { FormItemData } from '../../components/JJ_From';
import { JJ_FormSlider } from '../../components/JJ_FormSlider';
import JJ_FromSwitch from '../../components/JJ_FromSwitch';
import { putDynamicFieldWarnSet, getDynamicFieldWarnSet, getDynamicField } from '../../api/myApi';
import { FormInstance } from 'antd/lib/form';






export type WarnSetEditProps = {
  fieldId: string
}


export const WarnSetEdit = (props: WarnSetEditProps) => {


  const [warnSet, setWarnSet] = useState<GetDynamicFieldWarnSetRes>();
  const [field, setField] = useState<GetDynamicFieldRes>();


  const _onFinish = async (values: PutDynamicFieldWarnSetParameters & { limitRange: number[] }): Promise<void> => {
    console.log('_onFinish', values)

    try {
      await putDynamicFieldWarnSet({
        ...values,
        id: props.fieldId,
        maxLimit: values.limitRange[1] || 0,
        minLimit: values.limitRange[0] || 0
      })

      message.success(`${field?.label} 保存成功`)


    } catch (error) {
      message.success(`${field?.label} 失敗 「${error.message}」`)

    }



  }


  const _onLoadData = async (): Promise<any> => {
    const warnSet = await getDynamicFieldWarnSet({ id: props.fieldId, })
    const field = await getDynamicField({ id: props.fieldId, })
    setWarnSet(warnSet)
    setField(field)
    return {
      ...warnSet,
      limitRange: [warnSet.minLimit, warnSet.maxLimit]
    }

  };

  const _formListData = (): FormItemData[] => {

    return [

      {
        id: 'valueType',
        label: '值類型',
        rules: [
          {
            required: false,
          }
        ],
        componet: (
          <Select>
            {[
              {
                value: 'PCT',
                name: '百分比（%）',
              },
              {
                value: 'FIXED',
                name: '固定值',
              },
            ].map((quota) => {
              return (
                <Select.Option key={quota.value} value={quota.value}>{quota.name}</Select.Option>
              )
            })}
          </Select>
        )
      },

      {
        id: 'maxValue',
        label: '最大值',
        extra: '「固定值」才能編輯',
        rules: [
          {
            required: false,
          }
        ],
        componet: (
          <Input
            type='number'
            disabled={warnSet?.valueType === 'PCT'}
          />
        )
      },
      {
        id: 'minValue',
        label: '最小值',
        extra: '「固定值」才能編輯',
        rules: [
          {
            required: false,
          }
        ],
        componet: (
          <Input
            type='number'
            disabled={warnSet?.valueType === 'PCT'}
          />
        )
      },
      {
        id: 'limitRange',
        label: '告警範圍',
        extra: '設置範圍內的不告警，超出設定範圍內的會告警',
        rules: [
          {
            required: false,
          }
        ],
        componet: (
          <Slider
            range
            min={warnSet?.minValue}
            max={warnSet?.maxValue}
          />
        )
      },

      {
        id: 'isWarn',
        label: '是否告警',
        extra: '啟用後，觸發告警會發送至slack',
        rules: [
          {
            required: false,
          }
        ],
        componet: (
          <JJ_FromSwitch />
        )
      },

      {
        id: 'warnTime',
        label: '告警時間',
        extra: '保持超出告警範圍觸發條件，單位：分鐘',
        rules: [
          {
            required: false,
          }
        ],
        componet: (
          <JJ_FormSlider min={0} max={60 * 2} />
        )
      },

      {
        id: 'warnCD',
        label: '告警冷切時間',
        extra: '延迟下次再触发，單位：分鐘',
        rules: [
          {
            required: false,
          }
        ],
        componet: (
          <JJ_FormSlider min={0} max={60 * 2} />
        )
      },

      {
        id: 'submit',
      },
    ]
  }

  let formRef: FormInstance<any> | null
  return (
    <Card
      title={field?.label}
    >

      <JJ_From

        formListData={_formListData()}
        onLoadData={_onLoadData}
        onFinish={_onFinish}
        formRef={ref => formRef = ref}
        formProps={{

          onValuesChange: (changedValues, values) => {
            if (warnSet) {

              const valueType: GetDynamicFieldWarnSetRes['valueType'] = changedValues?.valueType
              if (valueType && valueType !== warnSet.valueType) {
                warnSet.valueType = valueType
                if (valueType === 'PCT') {
                  warnSet.minValue = 0
                  warnSet.maxValue = 100

                  formRef?.setFieldsValue({
                    minValue: 0,
                    maxValue: 100,
                  })

                }
                setWarnSet({ ...warnSet })

              }

              const minValue: GetDynamicFieldWarnSetRes['minValue'] = Number(changedValues?.minValue)
              const maxValue: GetDynamicFieldWarnSetRes['maxValue'] = Number(changedValues?.maxValue)
              if (minValue && warnSet.minValue !== minValue) {

                warnSet.minValue = Number(minValue) || 0
                setWarnSet({ ...warnSet })

              }

              if (maxValue && warnSet.maxValue !== maxValue) {
                warnSet.maxValue = Number(maxValue) || 0
                setWarnSet({ ...warnSet })

              }

            }
          }
        }}
      />


    </Card>
  )
}



