import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, Spin, Typography, Space, Button } from 'antd';
import { GetDynamicFieldTemplateListRes, GetFieldWarnRes, GetFieldWarnListParameters } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import JJ_TableItemMoment from '../../components/JJ_TableItemMoment';
import { connect, ConnectedProps } from 'react-redux';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import { ActionUserRes } from '../../actions/ActionUser';
import tool from '../../tool';
import { getFieldWarnList } from '../../api/myApi';
import { ColumnsType } from 'antd/lib/table';
import config from '../../config';
import { getQueryString } from '../../tool/route';
import {  DownloadOutlined } from '@ant-design/icons';



interface PageState {
  isSpinLoading: boolean
  dynamicFieldTemplateList: GetDynamicFieldTemplateListRes['data']
}
interface Props {
}


interface Page {

}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class DynamicFieldList extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      dynamicFieldTemplateList: [],
      isSpinLoading: true,
    }
  }
  tableRef?: JJ_Table<GetFieldWarnRes> | null
  componentDidMount() {
    this._initData()
  }



  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })

      this.setState({
        isSpinLoading: false,
      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }

  _initHistory = (paramsObj: { [x: string]: string | undefined }) => {
    tool.route.pushParamsObjHistory(paramsObj, this.props.history)
  }


  _getColumns = (): ColumnsType<GetFieldWarnRes> => {
    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 50,
        render: (value) => (
          <JJ_TableItemCopyableId
            text={value}
          />
        )
      },

      {
        title: '名稱',
        dataIndex: ['dynamicField', 'label'],
        width: 200,
      },

      {
        title: '告警信息',
        dataIndex: 'message',
        key: 'message',
        width: 400,
        render: (value, record) => {
          return (
            <Typography.Paragraph
              ellipsis={{
                rows: 1,
                expandable: true,
              }}
              style={{
                whiteSpace: 'pre-wrap',
              }}
            >
              {value}
            </Typography.Paragraph>
          )
        }
      },

      {
        title: '模板名稱',
        dataIndex: ['template', 'name'],
        key: 'template.name',
        width: 150,
      },

      {
        title: '模板類型',
        dataIndex: ['template', 'type'],
        key: 'template.type',
        width: 150,

      },

      {
        title: '項目名稱',
        dataIndex: ['item', 'name'],
        key: 'item.name',
        width: 150,
      },
      {
        title: '最大值',
        dataIndex: 'maxValue',
        key: 'maxValue',
        width: 100,
      },
      {
        title: '最小值',
        dataIndex: 'minValue',
        key: 'minValue',
        width: 100,
      },

      {
        title: '告警時間(分鐘)',
        dataIndex: ['fieldWarnSet', 'warnTime'],
        key: 'fieldWarnSet.warnTime',
        width: 140,
      },

      {
        title: '告警CD(分鐘)',
        dataIndex: ['fieldWarnSet', 'warnCD'],
        key: 'fieldWarnSet.warnCD',
        width: 140,
      },

      {
        title: '創建時間',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 200,
        render: (value) => (
          <JJ_TableItemMoment
            date={value}
          />
        )
      },
    ]
  }

  params: any = {}

  render() {

    const params = new URLSearchParams(this.props.location.search);
    return (
      <div id="DynamicFieldList"
      >
        <Space>

          <Button
            type='primary'
            style={{ marginBottom: 5 }}
            onClick={() => {
              window.open(`${config.REACT_APP_API}/admin/field-warn/?${getQueryString({ ...this.params, dataType: 'EXCEL' })}`)
              // window.open(`${config.REACT_APP_API_URL}/admin/product/?  ${new URLSearchParams({ ...filterParams(this.params), dataType: 'EXCEL' }).toString()}`)
            }}
            icon={<DownloadOutlined />}
          >
            {`導出Excel`}
          </Button>

        </Space>


        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<GetFieldWarnRes, {
              desc: 'ascend' | 'descend'
            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={[
                {
                  type: 'select',
                  defaultValue: 'ascend',
                  key: 'desc',
                  options: [
                    {
                      value: 'ascend',
                      name: '升序',
                      disabled: false,
                    },
                    {
                      value: 'descend',
                      name: '降序',
                      disabled: false,
                    },
                  ]
                },
              ]}
              columns={this._getColumns()}

              tableProps={{
                scroll: {
                  x: this._getColumns().reduce((pValue, cValue) => pValue + (Number(cValue.width) || 0), 0)
                }
              }}

              title={'告警列表'}
              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  isAscend?: GetFieldWarnListParameters['isAscend']

                } = {}
                if (sourceItemBody) {
                  if (sourceItemBody.desc) {
                    params.isAscend = sourceItemBody.desc === 'ascend' ? true : false
                  }

                  this._initHistory({})

                }
                this.params = {
                  ...body,
                  ...params,
                }
                const res = await getFieldWarnList(this.params)

                return {
                  totalCount: res.totalCount,
                  data: res.data,
                }
              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(DynamicFieldList)

