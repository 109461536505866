import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetDynamicFieldTemplateListRes, GetDynamicFieldTemplateListParameters } from '../../api';
import { Select, Tag } from 'antd';
import { connect, ConnectedProps } from 'react-redux';



interface PageState {
  dynamicFieldTemplateList: GetDynamicFieldTemplateListRes['data']

}

export type DynamicFieldAddType = { type: 'add', dynamicFieldTemplateId?: string } | { type: 'edit', id: string }

interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & DynamicFieldAddType & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class DynamicFieldEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);

    this.state = {
      dynamicFieldTemplateList: [],
    }

  }
  componentDidMount() {
  }

  _onFinish = async (values: any): Promise<void> => {

    switch (this.props.type) {
      case 'edit': {
        const res = await api.PutDynamicField({
          ...values,
          id: this.props.id,
        })
        if (res.kind !== 'ok') throw Error(res.kind)
        this.props.onFinish && this.props.onFinish(this.props, this.props.id)
      }
        break
      case 'add': {
        const res = await api.PostDynamicField({
          ...values,
        })
        if (res.kind !== 'ok') throw Error(res.kind)
        this.props.onFinish && this.props.onFinish(this.props, 'xxxx')

      }
        break
    }
  }


  _getDynamicFieldTemplateList = async (params: GetDynamicFieldTemplateListParameters) => {
    const res = await api.GetDynamicFieldTemplateList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {
      case 'edit': {
        const res = await api.GetDynamicField({ id: this.props.id })

        if (res.kind === 'ok') {
          return res.data
        }
        throw Error(res.kind)
      }
      case 'add': {
        const dftList = await this._getDynamicFieldTemplateList({ count: 1000 })
        this.setState({
          dynamicFieldTemplateList: dftList.data,
        })

        return {
          dynamicFieldTemplateId: this.props.dynamicFieldTemplateId,
        }
      }

    }
  };

  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [
          {
            id: 'label',
            label: '字段綽號',
            rules: [
              {
                required: false,
              }
            ],
          },
          {
            id: 'typeUnit',
            label: '类别单位',
            rules: [
              {
                required: false,
              }
            ],
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {



        return [

          {
            id: 'dynamicFieldTemplateId',
            label: '字段模板',
            rules: [
              {
                required: true,
              }
            ],
            componet: (
              <Select>
                {this.state.dynamicFieldTemplateList.map((data) => {
                  return (
                    <Select.Option key={data.id} value={data.id}>{data.name}</Select.Option>
                  )
                })}
              </Select>
            )
          },
          {
            id: 'label',
            label: '字段綽號',
            rules: [
              {
                required: true,
              }
            ],
          },
          {
            id: 'typeUnit',
            label: '类别单位',
            rules: [
              {
                required: false,
              }
            ],
          },
          {
            id: 'key',
            label: 'Key',
            rules: [
              {
                required: true,
              }
            ],
          },
          {
            id: 'type',
            label: '類型',
            rules: [
              {
                required: true,
              }
            ],
            componet: (
              <Select>
                {[
                  'string',
                  'number',
                  'boolean'
                ].map((data) => {
                  return (
                    <Select.Option key={data} value={data}>{data}</Select.Option>
                  )
                })}
              </Select>
            )
          },
          {
            id: 'submit',
          },
        ]
      }

    }
  }

  render() {
    return (
      <div id="DynamicFieldEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </div>
    )
  }

}
export default connector(DynamicFieldEditAndAdd)
