import React, { } from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import SystemList from '../system/System';
import ProjectList from '../project/ProjectList';
import DynamicFieldTemplateList from '../dynamicFieldTemplate/DynamicFieldTemplateList';
import DynamicFieldList from '../dynamicField/DynamicFieldList';
import DynamicModelStatistics from '../dynamicModelStatistics/DynamicModelStatistics';
import SystemStatistics from '../system/SystemStatistics';
import FieldWarnList from '../fieldWarn/FieldWarnList';



interface PageState {


}


interface PageProps {

}

const { Content } = Layout;

export default class MainContent extends React.Component<PageProps, PageState>  {

  constructor (props: Readonly<PageProps>) {
    super(props);

    this.state = {

    };
  }

  render() {

    return (
      <Content
        className="site-layout-background"
        style={{
          margin: '24px 16px',
          padding: 24,
          overflow: 'auto',
        }}
      >
        <Switch>

          <Route path="/system-project/system/list" exact component={SystemList}></Route>
          <Route path="/system-project/project/list" exact component={ProjectList}></Route>
          <Route path="/dynamic/dynamic-field-template/list" exact component={DynamicFieldTemplateList}></Route>
          <Route path="/dynamic/dynamic-field/list" exact component={DynamicFieldList}></Route>
          <Route path="/dynamic-model/statistics" exact component={DynamicModelStatistics}></Route>
          <Route path="/system-project/system/statistics" exact component={SystemStatistics}></Route>
          <Route path="/dynamic/field-warn/list" exact component={FieldWarnList}></Route>
          <Route component={SystemList} />
        </Switch>
      </Content>
    )
  }

}

[].reduce((previousValue, currentValue, currentIndex, array: any[]) => {
  return {
    ...previousValue,
  }
}, {
  time: '',
  count: 0,
})