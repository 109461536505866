
function getRandomColor(): string {
    const min = 100; // 设置最小颜色分量值
    const max = 200; // 设置最大颜色分量值

    const r = Math.floor(Math.random() * (max - min + 1)) + min;
    const g = Math.floor(Math.random() * (max - min + 1)) + min;
    const b = Math.floor(Math.random() * (max - min + 1)) + min;

    return `rgb(${r},${g},${b})`;
}

function generateRandomColors(count: number): string[] {
    const colors: string[] = [];

    for (let i = 0; i < count; i++) {
        const randomColor = getRandomColor();
        colors.push(randomColor);
    }

    return colors;
}

const numberOfColors = 100;
export const randomColors = generateRandomColors(numberOfColors);