import React, { useEffect, useState } from 'react';
import { GetDynamicFieldRes } from '../../api';
import { Modal, Spin, Tabs } from 'antd';
import { getDynamicFieldList } from '../../api/myApi';
import { WarnSetEdit } from './WarnSetEdit';



export type WarnSetEditModalProps = {
  title?: string
  visible?: boolean
  onCancel?(): void
  dynamicFieldTemplateId: string
}

export const WarnSetEditModal = (props: WarnSetEditModalProps) => {

  const [fields, setFields] = useState<GetDynamicFieldRes[]>([])
  const [loading, setLoading] = useState(true)


  const init = async () => {
    try {
      setLoading(true)
      const fields = await getDynamicFieldList({ count: 1000, dynamicFieldTemplateId: props.dynamicFieldTemplateId })
      setFields(fields.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }


  useEffect(() => {
    (() => {
      init()
    })()
    return () => {
      console.log('WarnSetEditModal_卸載了')
    }
  }, [])

  return (
    <Modal
      maskClosable={false}
      title={props.title}
      visible={props.visible}
      footer={null}
      onCancel={props.onCancel}
      width={'85%'}
    >

      <Spin spinning={loading}>

        <Tabs
          defaultActiveKey="1"
          type='card'
          size={'large'}
        >
          {fields.map(item => {

            return (
              <Tabs.TabPane
                tab={item.label}
                key={item.id}
              >
                <WarnSetEdit
                  fieldId={item.id}
                />
              </Tabs.TabPane>
            )
          })}
        </Tabs>

      </Spin>


    </Modal >
  )
}


