import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import api, { GetDynamicFieldListRes, GetSystemListRes, GetSystemListParameters, GetDynamicFieldListParameters, GetDynamicFieldRes, GetDynamicFieldParameters, GetDynamicFieldTemplateListRes, GetDynamicFieldTemplateListParameters } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import JJ_TableItemAction from '../../components/JJ_TableItemAction';
import JJ_TableItemMoment from '../../components/JJ_TableItemMoment';
import { connect, ConnectedProps } from 'react-redux';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import { ActionUserRes } from '../../actions/ActionUser';
import DynamicFieldEditAndAdd, { DynamicFieldAddType } from './DynamicFieldAdd'
import { PlusOutlined } from '@ant-design/icons';
import tool from '../../tool';

const { confirm } = Modal;


interface PageState {
  isSpinLoading: boolean
  editAndAddModal: {
    show: boolean
    key: string
  } & DynamicFieldAddType
  dynamicFieldTemplateList: GetDynamicFieldTemplateListRes['data']
}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<GetDynamicFieldRes> | null
}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class DynamicFieldList extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      dynamicFieldTemplateList: [],
      isSpinLoading: true,
      editAndAddModal: {
        type: 'add',
        show: false,
        key: new Date().toString()
      },
    }
  }
  tableRef?: JJ_Table<GetDynamicFieldRes> | null
  componentDidMount() {
    this._initData()
  }


  _getDynamicFieldList = async (params: GetDynamicFieldListParameters) => {
    const res = await api.GetDynamicFieldList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getDynamicFieldTemplateList = async (params: GetDynamicFieldTemplateListParameters) => {
    const res = await api.GetDynamicFieldTemplateList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getSystemList = async (params: GetSystemListParameters) => {
    const res = await api.GetSystemList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })
      const dftList = await this._getDynamicFieldTemplateList({ count: 1000 })
      this.setState({
        isSpinLoading: false,
        dynamicFieldTemplateList: dftList.data,
      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }

  _initHistory = (paramsObj: { [x: string]: string | undefined }) => {
    tool.route.pushParamsObjHistory(paramsObj, this.props.history)
  }


  render() {

    const params = new URLSearchParams(this.props.location.search);
    const dynamicFieldTemplateId = params.get('dynamicFieldTemplateId') || undefined
    return (
      <div id="DynamicFieldList"
      >

        <Space>

          <Button
            type='primary'
            style={{ marginBottom: 5 }}
            onClick={() => {
              this.setState(state => ({
                editAndAddModal: {
                  ...state.editAndAddModal,
                  show: true,
                  key: new Date().toString(),
                  type: 'add',
                  dynamicFieldTemplateId,
                }
              }))
            }}
            icon={<PlusOutlined />}
          >
            {`添加字段`}
          </Button>

        </Space>



        <Modal
          maskClosable={false}
          title={this.state.editAndAddModal.type === 'edit' ? '編輯字段' : '新建字段'}
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          <DynamicFieldEditAndAdd
            {...this.state.editAndAddModal}
            onFinish={async (props) => {
              this.setState(state => ({
                editAndAddModal: { ...state.editAndAddModal, show: false }
              }))
              switch (props.type) {
                case 'add': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`添加成功`)
                }
                  break
                case 'edit': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`編輯成功`)
                }
              }

            }}
          />
        </Modal>

        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<GetDynamicFieldRes, {
              desc: 'ascend' | 'descend'
              dynamicFieldTemplateId: GetDynamicFieldListParameters['dynamicFieldTemplateId']
            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={[
                {
                  type: 'select',
                  defaultValue: 'ascend',
                  key: 'desc',
                  options: [
                    {
                      value: 'ascend',
                      name: '升序',
                      disabled: false,
                    },
                    {
                      value: 'descend',
                      name: '降序',
                      disabled: false,
                    },
                  ]
                },
                {
                  type: 'select',
                  defaultValue: dynamicFieldTemplateId,
                  placeholder: '任意模板',
                  allowClear: true,
                  key: 'dynamicFieldTemplateId',
                  options: this.state.dynamicFieldTemplateList.map(item => ({
                    value: item.id,
                    name: item.name,
                    disabled: false,
                  })),
                },

              ]}
              columns={[
                {
                  key: 'action',
                  width: 50,
                  render: (value: any, record) => {
                    return (
                      <JJ_TableItemAction
                        menus={[
                          {
                            type: 'item',
                            disabled: false,
                            key: 'edit',
                            name: '編輯',
                            onAction: () => {
                              this.setState(state => ({
                                editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                              }))
                            }
                          },
                          {
                            type: 'item',
                            disabled: false,
                            key: 'delete',
                            name: '删除',
                            onAction: () => {
                              confirm({
                                title: `是否刪除${record.label}`,
                                icon: <ExclamationCircleOutlined />,
                                okText: 'Yes',
                                okType: 'danger',
                                cancelText: 'No',
                                onOk: async () => {
                                  try {
                                    await api.DeleteDynamicField({ id: record.id })
                                    this.tableRef && this.tableRef.refreshData()
                                    message.success(`${record.label} 删除成功`)
                                  } catch (error) {
                                    message.error(`${record.label} 删除失败 [${error.message}]`);
                                  }
                                },
                                onCancel() {
                                },
                              });
                            }
                          },
                        ]}
                      />
                    )

                  },
                },
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  width: 50,
                  render: (value) => (
                    <JJ_TableItemCopyableId
                      text={value}
                    />
                  )
                },

                {
                  title: '字段綽號',
                  dataIndex: 'label',
                  key: 'label',
                  width: 200,
                },

                {
                  title: '字段模板',
                  dataIndex: 'dynamicFieldTemplateId',
                  key: 'dynamicFieldTemplateId',
                  width: 200,
                  render: (value, record) => {
                    const dft = this.state.dynamicFieldTemplateList.find(item => item.id === value)
                    return dft?.name
                  }
                },

                {
                  title: 'key',
                  dataIndex: 'myKey',
                  key: 'myKey',
                  width: 200,

                },

                {
                  title: '類別',
                  dataIndex: 'type',
                  key: 'type',
                  width: 200,
                },

                {
                  title: '類別單位',
                  dataIndex: 'typeUnit',
                  key: 'typeUnit',
                  width: 200,
                }
                ,
                {
                  title: '類別單位',
                  dataIndex: 'typeUnit',
                  key: 'typeUnit',
                  width: 200,
                },

                {
                  title: '創建時間',
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  width: 200,
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },
              ]}
              title={'字段列表'}
              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  isAscend?: GetDynamicFieldListParameters['isAscend']
                  dynamicFieldTemplateId?: GetDynamicFieldListParameters['dynamicFieldTemplateId']
                } = {}
                if (sourceItemBody) {
                  if (sourceItemBody.desc) {
                    params.isAscend = sourceItemBody.desc === 'ascend' ? true : false
                  }

                  if (sourceItemBody.dynamicFieldTemplateId) {
                    params.dynamicFieldTemplateId = sourceItemBody.dynamicFieldTemplateId

                  }


                  this._initHistory({
                    dynamicFieldTemplateId: sourceItemBody.dynamicFieldTemplateId
                  })

                }
                const res = await api.GetDynamicFieldList({
                  ...body,
                  ...params,
                })
                if (res.kind !== 'ok') throw new Error(res.kind)
                return {
                  totalCount: res.data.totalCount,
                  data: res.data.data.map((item: any) => {
                    item['myKey'] = item.key
                    return item
                  })
                }
              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(DynamicFieldList)

