import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, Spin, Row, Col, Statistic, Card } from 'antd';
import { GetSystemRes, GetProjectRes } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import { connect, ConnectedProps } from 'react-redux';
import { ActionUserRes } from '../../actions/ActionUser';
import { getSystemList, getProjectList } from '../../api/myApi';
import moment from 'moment';
import { UpdateIfOver24Hour } from './UpdateIfOver24Hour';
import { SysDistMap } from './SysDistMap';



interface PageState {
  isSpinLoading: boolean
  systems: GetSystemRes[]
  projects: GetProjectRes[]

  sysNotUpd: GetSystemRes[]
  proNotUpd: GetProjectRes[]
}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<GetSystemRes> | null
}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class SystemStatistics extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      systems: [],
      projects: [],
      sysNotUpd: [],
      proNotUpd: [],
    }
  }
  tableRef?: JJ_Table<GetSystemRes> | null
  componentDidMount() {
    this._initData()
  }



  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })

      const systemList = await getSystemList({ count: 1000 })
      const projectList = await getProjectList({ count: 1000 })

      this.setState({
        isSpinLoading: false,
        systems: systemList.data,
        projects: projectList.data,
        sysNotUpd: systemList.data.filter(item => this._hourFilter(24, item.updatedAt)),
        proNotUpd: projectList.data.filter(item => this._hourFilter(24, item.updatedAt)),
      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }


  _hourFilter = (h: number, value?: string) => {

    //  获取当前时间
    const now = moment();
    // 获取另一个时间，例如某个特定的日期和时间
    const otherTime = moment(value);

    // 计算两个时间之间的差异
    const duration = moment.duration(now.diff(otherTime));

    // 获取小时数
    const hours = duration.asHours();
    // 判断是否大于24小时

    return hours >= h || !value
  }

  _view = () => {
    return (
      <>
        <UpdateIfOver24Hour
          sysNotUpdCount={this.state.sysNotUpd.length}
          sysCount={this.state.systems.length}
          proNotUpdCount={this.state.proNotUpd.length}
          proCount={this.state.projects.length}
        />
        <div style={{ height: 20 }} />
        <SysDistMap
        />

      </>

    )
  }

  render() {

    return (
      <div id="SystemStatistics"
      >

        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && this._view()}
        </Spin>

      </div>
    )
  }

}
export default connector(SystemStatistics)

