import React from 'react';
import { Col, InputNumber, Row, Slider } from 'antd';

export type JJ_FormSliderProps = {
  min: number
  max: number
  value?: number
  onChange?: (value?: number | {}) => void;

}

export const JJ_FormSlider = (props: JJ_FormSliderProps) => {

  const { onChange, value } = props

  return (
    <Row>
      <Col span={12}>
        <Slider
          min={props.min}
          max={props.max}
          onChange={onChange}
          value={typeof value === 'number' ? value : 0}
        />
      </Col>
      <Col span={4}>
        <InputNumber
          min={props.min}
          max={props.max}
          style={{ margin: '0 16px' }}
          value={value}
          onChange={onChange}
        />
      </Col>
    </Row>
  );
};

