import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import api, { GetDynamicFieldTemplateRes, GetDynamicFieldListRes, GetDynamicFieldTemplateListRes, GetSystemListRes, GetSystemListParameters, GetDynamicFieldTemplateListParameters } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import JJ_TableItemAction from '../../components/JJ_TableItemAction';
import JJ_TableItemMoment from '../../components/JJ_TableItemMoment';
import { connect, ConnectedProps } from 'react-redux';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import { ActionUserRes } from '../../actions/ActionUser';
import DynamicFieldTemplateEditAndAdd from './DynamicFieldTemplateAdd'
import { PlusOutlined } from '@ant-design/icons';
import { WarnSetEditModal } from './WarnSetEditModal';

const { confirm } = Modal;

type EditAndAddModalType = { type: 'add' } | { type: 'edit', id: string }

interface PageState {
  isSpinLoading: boolean
  editAndAddModal: {
    show: boolean
    key: string
  } & EditAndAddModalType

  warnSetEditModalState?: {
    visible?: boolean
    dynamicFieldTemplateId: string
    key?: any
  }
}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<GetDynamicFieldTemplateRes> | null
}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class DynamicFieldTemplateList extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      editAndAddModal: {
        type: 'add',
        show: false,
        key: new Date().toString()
      }
    }
  }
  tableRef?: JJ_Table<GetDynamicFieldTemplateRes> | null
  componentDidMount() {
    this._initData()
  }


  _getDynamicFieldTemplateList = async (params: GetDynamicFieldTemplateListParameters) => {
    const res = await api.GetDynamicFieldTemplateList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getSystemList = async (params: GetSystemListParameters) => {
    const res = await api.GetSystemList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getDynamicFieldTemplateTypeInfo = (type: GetDynamicFieldTemplateRes['type']) => {

    switch (type) {
      case 'Project': {
        const name = '項目'
        return {
          value: type,
          name,
          tag: (
            <Tag color='pink'>
              {name}
            </Tag>
          )
        }
      }

      case 'System': {
        const name = '系統'
        return {
          value: type,
          name,
          tag: (
            <Tag color='blue'>
              {name}
            </Tag>
          )
        }
      }

    }
  }

  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })

      this.setState({
        isSpinLoading: false,
      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }
  render() {

    return (
      <div id="DynamicFieldTemplateList"
      >

        <Space>

          <Button
            type='primary'
            style={{ marginBottom: 5 }}
            onClick={() => {
              this.setState(state => ({
                editAndAddModal: {
                  ...state.editAndAddModal,
                  show: true,
                  key: new Date().toString(),
                  type: 'add',
                }
              }))
            }}
            icon={<PlusOutlined />}
          >
            {`添加字段模板`}
          </Button>

        </Space>
        {this.state.warnSetEditModalState && (
          <WarnSetEditModal
            {...this.state.warnSetEditModalState}
            title={'告警設置'}
            onCancel={() => {
              if (this.state.warnSetEditModalState) {
                this.setState({
                  warnSetEditModalState: {
                    ...this.state.warnSetEditModalState,
                    visible: false,
                  }
                })
              }
            }}
          />
        )}


        <Modal
          maskClosable={false}
          title={this.state.editAndAddModal.type === 'edit' ? '編輯字段模板' : '新建字段模板'}
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          <DynamicFieldTemplateEditAndAdd
            {...this.state.editAndAddModal}
            onFinish={async (props) => {
              this.setState(state => ({
                editAndAddModal: { ...state.editAndAddModal, show: false }
              }))
              switch (props.type) {
                case 'add': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`添加成功`)
                }
                  break
                case 'edit': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`編輯成功`)
                }
              }

            }}
          />
        </Modal>

        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<GetDynamicFieldTemplateRes, {
              desc: 'ascend' | 'descend'
              type: GetDynamicFieldTemplateRes['type']
            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={[
                {
                  type: 'select',
                  defaultValue: 'ascend',
                  key: 'desc',
                  options: [
                    {
                      value: 'ascend',
                      name: '升序',
                      disabled: false,
                    },
                    {
                      value: 'descend',
                      name: '降序',
                      disabled: false,
                    },
                  ]
                },
                {
                  type: 'select',
                  defaultValue: undefined,
                  placeholder: '任意類型',
                  allowClear: true,
                  key: 'type',
                  options: [
                    {
                      value: 'System',
                      name: this._getDynamicFieldTemplateTypeInfo('System').tag,
                    },
                    {
                      value: 'Project',
                      name: this._getDynamicFieldTemplateTypeInfo('Project').tag,
                    },
                  ].map(item => ({
                    value: item.value,
                    name: item.name,
                    disabled: false,
                  })),
                },
              ]}
              columns={[
                {
                  key: 'action',
                  width: 50,
                  render: (value: any, record) => {
                    return (
                      <JJ_TableItemAction
                        menus={[
                          {
                            type: 'item',
                            disabled: false,
                            key: 'ViewFields',
                            name: '查看字段',
                            onAction: () => {
                              this.props.history.push(`/dynamic/dynamic-field/list?dynamicFieldTemplateId=${record.id}`)
                            }
                          },

                          {
                            type: 'item',
                            disabled: false,
                            key: 'statistics',
                            name: '查看統計',
                            onAction: () => {
                              window.open(`#/dynamic-model/statistics?dynamicFieldTemplateId=${record.id}&collapsed=true`, '_blank')
                            }
                          },
                          {
                            type: 'item',
                            disabled: false,
                            key: 'edit',
                            name: '編輯',
                            onAction: () => {
                              this.setState(state => ({
                                editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                              }))
                            }
                          },
                          {
                            type: 'item',
                            disabled: false,
                            key: '告警設置',
                            name: '告警設置',
                            onAction: () => {

                              this.setState({
                                warnSetEditModalState: {
                                  visible: true,
                                  key: Date.now(),
                                  dynamicFieldTemplateId: record.id,
                                }
                              })

                            }
                          },
                          {
                            type: 'item',
                            disabled: false,
                            key: 'delete',
                            name: '删除',
                            onAction: () => {
                              confirm({
                                title: `是否刪除${record.name}`,
                                icon: <ExclamationCircleOutlined />,
                                okText: 'Yes',
                                okType: 'danger',
                                cancelText: 'No',
                                onOk: async () => {
                                  try {
                                    await api.DeleteDynamicFieldTemplate({ id: record.id })
                                    this.tableRef && this.tableRef.refreshData()
                                    message.success(`${record.name} 删除成功`)
                                  } catch (error) {
                                    message.error(`${record.name} 删除失败 [${error.message}]`);
                                  }
                                },
                                onCancel() {
                                },
                              });
                            }
                          },
                        ]}
                      />
                    )

                  },
                },
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  width: 50,
                  render: (value) => (
                    <JJ_TableItemCopyableId
                      text={value}
                    />
                  )
                },

                {
                  title: '名稱',
                  dataIndex: 'name',
                  key: 'name',
                  width: 200,
                },
                {
                  title: '類型',
                  dataIndex: 'type',
                  key: 'type',
                  render: (value, record) => {

                    return this._getDynamicFieldTemplateTypeInfo(value)?.tag
                  }
                },
                {
                  title: '創建時間',
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },
              ]}
              title={'字段模板列表'}
              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  isAscend?: GetDynamicFieldTemplateListParameters['isAscend']
                  type?: GetDynamicFieldTemplateListParameters['type']
                } = {}
                if (sourceItemBody) {
                  if (sourceItemBody.desc) {
                    params.isAscend = sourceItemBody.desc === 'ascend' ? true : false
                  }
                  params.type = sourceItemBody.type
                }
                const res = await api.GetDynamicFieldTemplateList({
                  ...body,
                  ...params,
                })
                if (res.kind !== 'ok') throw new Error(res.kind)
                return res.data
              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(DynamicFieldTemplateList)

