import React, { } from 'react';
import { Row, Col, Statistic, Card } from 'antd';

export type UpdateIfOver24HourProps = {
  sysNotUpdCount: number
  sysCount: number
  proNotUpdCount: number
  proCount: number
}

/**
 * 超過24小時沒有更新
 */
export const UpdateIfOver24Hour = (props: UpdateIfOver24HourProps) => {
  return (
    <Col span={12}>
      <Card
        title={'超過24小時沒有更新'}
      >
        <Row>
          <Col span={12}>
            <Statistic title="系統" valueStyle={{ color: 'red' }} value={`${props.sysNotUpdCount}`} suffix={<div style={{ fontSize: 18, color: 'black' }}> / {props.sysCount}</div>} />
          </Col>

          <Col span={12}>
            <Statistic title="項目" valueStyle={{ color: 'red' }} value={`${props.proNotUpdCount}`} suffix={<div style={{ fontSize: 18, color: 'black' }}> / {props.proCount}</div>} />
          </Col>
        </Row>
      </Card>
    </Col>


  )
}